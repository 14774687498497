<template>
    <BaseModal
        v-model="model"
        size="md">
        <div class="flex flex-col items-center justify-center m-8">
            <LogoImage class="h-8" />
            <div>
                <small class="text-neutral-400 dark:text-gray-300">{{ runtimeConfig.public.frontendUrl }}</small>
            </div>
        </div>
        <div class="text-center">
            <div class="flex flex-col items-center justify-center m-4">
                <h2 class="font-bold">
                    {{ runtimeConfig.app.clientVersion }}
                </h2>
                <div
                    v-if="versionIsUpToDate"
                    class="text-neutral-400 dark:text-gray-300 flex items-center gap-1">
                    <small>{{ $t('users.info.isCurrentVersion') }}</small>
                    <Icon
                        name="heroicons:check-circle"
                        class="text-success-500" />
                </div>
                <div
                    v-else-if="versionMismatch"
                    class="text-neutral-400 dark:text-gray-300 flex items-center gap-1 cursor-pointer"
                    :title="$t('users.info.loadNewestVersion')"
                    @click="reloadPage">
                    <small>{{ $t('users.info.isOldVersion') }}</small>
                    <Icon
                        name="heroicons:x-circle"
                        class="text-danger-500" />
                </div>
            </div>
        </div>

        <div class="relative m-6">
            <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true">
                <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center">
                <span
                    class="bg-white dark:bg-gray-700 px-3 text-base font-semibold leading-6 text-gray-900 dark:text-white">{{
                    $t('users.info.releaseNotes')
                }}</span>
            </div>
        </div>

        <div class="max-h-[60vh] overflow-y-scroll">
            <ReleaseNotes />
        </div>
    </BaseModal>
</template>

<script setup>

import {useAuthStore} from '@/store/auth'
import ReleaseNotes from '@/components/layouts/UserMenu/ReleaseNotes'

const props = defineProps({
    modelValue: {
        type: Boolean
    }
})

const emits = defineEmits(['update:modelValue'])

const model = computed({
    get: () => props.modelValue,
    set: (newValue) => {
        emits('update:modelValue', newValue)
    }
});

const authStore = useAuthStore()
const runtimeConfig = useRuntimeConfig()
const {versionIsUpToDate, versionMismatch} = useAppVersion()

const reloadPage = () => {
    authStore.setRemoteVersion(null)
    window.location.reload()
}
</script>

<style scoped>

</style>