export const releaseNotesDe: ReleaseNotes = [
    {
        "release": "0.0.0",
        "date": "2024-06-18",
        "tags": ["Projektstart"],
        "type": "major",
        "notes": [
            {
                "description": "Das Projekt wurde aufgesetzt. Die Entwicklung kann beginnen!"
            }
        ],
    }
]