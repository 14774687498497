export const releaseNotesEn: ReleaseNotes = [
    {
        release: "0.0.0",
        "date": "2024-06-18",
        tags: [
            "Start of Project"
        ],
        type: "major",
        notes: [
            {
                "description": "The project has been deployed. Development can commence!"
            }
        ]
    }
]