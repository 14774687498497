import {$getModuleColor, $getModuleIcon} from "./appModules";

export const sidebarMenu: SidebarMenu = [
    {
        title: 'default',
        menu: [
            {
                title: 'menu.dashboard',
                path: '/',
                icon: $getModuleIcon('dashboard'),
                color: $getModuleColor('dashboard'),
            },
            {
                title: 'menu.orders',
                path: 'orders',
                ability: ['update', 'users'],
                icon: $getModuleIcon('orders'),
                color: $getModuleColor('orders'),
            },
            {
                title: 'menu.merchants',
                path: 'merchants',
                ability: ['update', 'merchants'],
                icon: $getModuleIcon('merchants'),
                color: $getModuleColor('merchants'),
            },
            {
                title: 'menu.settings',
                path: 'settings',
                icon: $getModuleIcon('settings'),
                color: $getModuleColor('settings'),
                children: [
                    {
                        title: 'menu.users',
                        path: 'users',
                        icon: $getModuleIcon('users'),
                        color: $getModuleColor('users'),
                        ability: ['update', 'users']
                    },
                    {
                        title: 'menu.statistics',
                        path: 'statistics',
                        ability: ['update', 'users']
                    },
                    {
                        title: 'menu.loginProviders',
                        path: 'settings-loginProviders',
                        ability: ['update', 'loginProviders']
                    },
                ]
            }
        ]
    },
];